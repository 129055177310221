// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-assists-js": () => import("./../src/pages/assists.js" /* webpackChunkName: "component---src-pages-assists-js" */),
  "component---src-pages-complementary-assistance-services-js": () => import("./../src/pages/complementary_assistance_services.js" /* webpackChunkName: "component---src-pages-complementary-assistance-services-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-effective-payment-management-js": () => import("./../src/pages/effective_payment_management.js" /* webpackChunkName: "component---src-pages-effective-payment-management-js" */),
  "component---src-pages-human-resources-js": () => import("./../src/pages/human_resources.js" /* webpackChunkName: "component---src-pages-human-resources-js" */),
  "component---src-pages-ica-progressive-investment-js": () => import("./../src/pages/ica_progressive_investment.js" /* webpackChunkName: "component---src-pages-ica-progressive-investment-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-initial-training-js": () => import("./../src/pages/initial_training.js" /* webpackChunkName: "component---src-pages-initial-training-js" */),
  "component---src-pages-insurance-js": () => import("./../src/pages/insurance.js" /* webpackChunkName: "component---src-pages-insurance-js" */),
  "component---src-pages-labor-legal-advice-and-consulting-js": () => import("./../src/pages/labor_legal_advice_and_consulting.js" /* webpackChunkName: "component---src-pages-labor-legal-advice-and-consulting-js" */),
  "component---src-pages-legal-advice-js": () => import("./../src/pages/legal_advice.js" /* webpackChunkName: "component---src-pages-legal-advice-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/News.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-pension-advice-js": () => import("./../src/pages/pension_advice.js" /* webpackChunkName: "component---src-pages-pension-advice-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/Services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-taxes-and-tax-planning-js": () => import("./../src/pages/taxes_and_tax_planning.js" /* webpackChunkName: "component---src-pages-taxes-and-tax-planning-js" */)
}

